import { NgModule } from '@angular/core';

import { KeysPipe } from './keys.pipe';
import { GetByIdPipe } from './getById.pipe';
import { HtmlToPlaintextPipe } from './htmlToPlaintext.pipe';
import { FilterPipe } from './filter.pipe';
import { CamelCaseToDashPipe } from './camelCaseToDash.pipe';
import { DateTimeFormatPipe } from './date-pipes/date-time-formate';
import { SummeryPipe } from './summery.pipe';
import { GroupByArrPipe } from './groupBy.pipe';
import { SearchPipe } from './search.pipe';
import { SinglefilterPipe } from './singlefilter.pipe';
import { FilesizePipe } from './filesize.pipe';
import { MathcesStatusPipe } from './filterByStatus.pipe';



@NgModule({
    declarations: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        DateTimeFormatPipe,
        SummeryPipe,
        GroupByArrPipe,
        SearchPipe,
        SinglefilterPipe,
        FilesizePipe,
        MathcesStatusPipe
        
    ],
    imports: [],
    exports: [
        KeysPipe,
        GetByIdPipe,
        HtmlToPlaintextPipe,
        FilterPipe,
        CamelCaseToDashPipe,
        SummeryPipe,
        GroupByArrPipe,
        SearchPipe,
        SinglefilterPipe,
        FilesizePipe,
        MathcesStatusPipe
        
    ]
})
export class FusePipesModule {
}
