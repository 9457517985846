import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PlanworkModule } from '@planwork/planwork.module';
import { PlanworkSharedModule } from '@planwork/planwork.shared.module';
import { planworkConfig } from './planwork.config';
import { MatTreeModule } from '@angular/material/tree';
import { MatTableModule } from '@angular/material/table';
import { MatCardModule } from '@angular/material/card';
import { RouterModule } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { NgxSpinnerModule } from "ngx-spinner";
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { FuseMatSelectSearchModule } from '@planwork/mat-select-search/mat-select-search.module';
import { ReactiveFormsModule } from '@angular/forms';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { CommonModalComponent } from './common-modal/common-modal.component';
import { ThankYouComponent } from './thank-you/thank-you.component';
import { ExpiredComponent } from './expired/expired.component';



@NgModule({
  declarations: [
    AppComponent,
    CommonModalComponent,
    ThankYouComponent,
    ExpiredComponent,


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    PlanworkSharedModule,
    MatTreeModule,
    MatTableModule,
    MatCardModule,
    HttpClientModule,
    NgxSpinnerModule,
    FuseMatSelectSearchModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    PlanworkModule.forRoot(planworkConfig),
    CalendarModule.forRoot({ provide: DateAdapter, useFactory: adapterFactory })
  ],
  exports: [
    PlanworkSharedModule
  ],
  providers: [
    { provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS, useValue: { useUtc: true } }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule { }
