<div class="dialog-content-wrapper admin_modal prsn_dialog common-modal text-center">
    <button mat-icon-button class="m_close" (click)="dialogRef.close(false)">
                <mat-icon>close</mat-icon>
        </button>
    <div class="modal_section">
        <h1 class="text-success" matDialogTitle *ngIf="dialogAction == 'success'">
            <img src="/assets/images/success.svg" style="display: block; margin: auto;"> Success!
        </h1>
        <h1 class="text-warning" matDialogTitle *ngIf="dialogAction == 'delete' || dialogAction == 'Alert' || dialogAction == 'gobacktolist'">
            <img src="/assets/images/caution.png" class="d-block m-auto" style="height: 50px; width: 50px;"> Alert!
        </h1>
    </div>

    <div mat-dialog-content>
        <span *ngIf="statusText" [innerHTML]="statusText"></span>
    </div>
    <div mat-dialog-actions class="justify-content-center">
        <button mat-flat-button class="btn mat-accent mr-16" color="primary" *ngIf="dialogAction == 'success'" (click)="dialogRef.close(true)">Continue</button>
        <button mat-flat-button class="btn mat-accent mr-16" color="primary" *ngIf="dialogAction == 'delete'" (click)="dialogRef.close(true)">Confirm</button>

        <button mat-flat-button class="btn mat-accent mr-16" color="primary" *ngIf="dialogAction == 'gobacktolist'" (click)="dialogRef.close(true)" routerLink="/app/core/common/exit-employee">Back To List</button>

        <button class="btn-cancel btn" mat-button *ngIf="dialogAction == 'delete'" (click)="dialogRef.close(false)">Cancel</button>
        <button mat-flat-button class="btn mat-accent mr-16" color="primary" *ngIf="dialogAction == 'Alert'" (click)="dialogRef.close(true)">Ok</button>
    </div>

    <div *ngIf="dialogAction == 'Import'">
        <button mat-flat-button class="btn mat-accent mr-16" color="primary" *ngIf="dialogAction == 'Import'" routerLink="/app/campaign/raw-data" (click)="dialogRef.close(true)">Ok</button>
        <!-- <button class="btn-cancel btn" mat-button *ngIf="dialogAction == 'Import'"
                        (click)="dialogRef.close(false)">Cancel</button> -->
    </div>

    <div *ngIf="dialogAction == 'showEmployee'">
        <table class="table">
            <thead>
                <tr>
                    <td>Resource Name</td>
                    <td>Delivery qty</td>
                </tr>
            </thead>
            <tbody>
                <tr *ngFor="let list of getData.VerifyDeliveryCyclesResourceList; let i = index">
                    <td>{{list.EmployeeName ? list.EmployeeName : 'Resource ' + (i + 1)}}</td>
                    <td>{{list.DeliveredQuantity}}</td>
                </tr>
            </tbody>
        </table>
    </div>

</div>
<div class="modal_section">
    <h2>{{modalTitle}}</h2>
<div *ngIf="data.action == 'AddCityName' || data.action == 'CityNameEdit'">
    <div>
        <form class="row " autocomplete="off" (ngSubmit)="myForm.form.valid" #myForm="ngForm" novalidate
            autocomplete="off">
            <div class=" col-sm-12">
                <div class="" class="d-matlab">
                    <mat-form-field appearance="outline">
                        <mat-label>Select Country</mat-label>
                        <mat-select (selectionChange)="SelectStateList($event)"
                            [(ngModel)]="saveCityInput.CountryId" name="Country">
                            <fuse-mat-select-search name="search" [(ngModel)]="countrySearch">
                            </fuse-mat-select-search>
                            <mat-option *ngFor="let Country of CountryDropdownlist | filter: countrySearch"
                                [value]="Country?.CountryId">
                                {{Country.CountryName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <div class="" class="d-matlab">
                    <mat-form-field appearance="outline">
                        <mat-label>Select State</mat-label>
                        <mat-select [(ngModel)]="saveCityInput.StateId" name="State">
                            <fuse-mat-select-search name="search" [(ngModel)]="stateSearch">
                            </fuse-mat-select-search>
                            <mat-option *ngFor="let State of StateList | filter: stateSearch"
                                [value]="State?.StateId">
                                {{State.StateName}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
            <div class=" col-sm-12">
                <div class="" class="d-matlab">
                    <mat-form-field appearance="outline">
                        <mat-label>City</mat-label>
                        <input matInput type="text" class="form-control" [(ngModel)]="saveCityInput.CityName"
                            name="CityName" required>
                    </mat-form-field>
                </div>
            </div>
            <!-- <div class=" col-sm-12">
    <div class="" class="d-matlab">
      <mat-form-field appearance="outline">
        <mat-label>City Code</mat-label>
        <input matInput type="text" class="form-control" [(ngModel)]="saveCityInput.CityCode" name="CityCode"
          required>
      </mat-form-field>
    </div>
  </div> -->
            <div class=" col-sm-4">
                <button type="submit" mat-flat-button color="primary" [disabled]="myForm.form.invalid"
                    (click)="savecity(saveCityInput,  'CitySave')">
                    <span *ngIf="data.action == 'AddCityName'">Save</span>
                    <span *ngIf="data.action == 'CityNameEdit'">Update</span>
                </button>
            </div>
        </form>
    </div>
</div>
</div>
