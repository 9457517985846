<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" size="medium" color="#fff" type="timer" [fullScreen]="true">
  <p style="font-size: 20px; color: white">Loading...</p>
</ngx-spinner>


<div id="planwork-main-content">
  <div class="page-layout">
    <div id="main_content_main_page" class="sidebar_Show">

      <router-outlet></router-outlet>
    </div>
  </div>
</div>
