import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ExpiredComponent } from './expired/expired.component';
import { ThankYouComponent } from './thank-you/thank-you.component';



const routes: Routes = [
  {
    path: 'employee',
    loadChildren: () => import('./add-employee-new/add-employee-new.module').then(m => m.AddEmployeeNewModule)
  },
  {
    path: ":Id/:CId",
    loadChildren: () => import('./add-employee-new/add-employee-new.module').then(m => m.AddEmployeeNewModule)
  },
  {
    path: 'rfq-format',
    loadChildren: () => import('./rfq-format/rfq-format.module').then(m => m.RfqFormatModule)
  },
  {
    path: 'thank-you',
    component: ThankYouComponent
  },

  {
    path: '',
    component: ExpiredComponent
  },
  {
    path: '**',
    redirectTo: ''
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
