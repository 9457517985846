import { Component, OnInit, Inject } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DropdownService } from '@planwork/services/dropdown.service';
import { ServiceService } from 'app/masterservice/service.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-common-modal',
  templateUrl: './common-modal.component.html',
  styleUrls: ['./common-modal.component.scss']
})
export class CommonModalComponent implements OnInit {
  dialogAction: any;
  confirmMessage: any;

  statusText: any;
  getData: any;
  CountryList: any
  baseGlobalConfig='GlobalCore/api/Configuration/'
  stateSearch='';
  countrySearch='';
  StateList: any;
  response:any;
  CountryDropdownlist= []=[];
  modalTitle: any;
  constructor(
    public dialogRef: MatDialogRef<CommonModalComponent>,
    private spinner: NgxSpinnerService,
    private dropdownService: DropdownService,
    public dialog:MatDialog,
    private masterService: ServiceService,
    @Inject(MAT_DIALOG_DATA) public data: any,) {
    console.log(this.getData);
  
    console.log(this.data);
    this.modalTitle = data.modalTitle
    this.dialogAction = data.action;
    this.statusText = data.statusText;
    this.getData = data.getData;


  }
  saveCityInput: any = {};
 async ngOnInit() {
    if(this.data.action == "AddCityName"){

      if (this.CountryDropdownlist.length == 0) {
        let CountryDropdownemp = await this.Countrylist();
        this.CountryDropdownlist = CountryDropdownemp.Result;
      }
    }

  }
  Countrylist(): Promise<any> {
    return this.dropdownService.CountryDropDownlist({}).toPromise()
  }
  SelectStateList(a) {
    console.log(a);

    this.spinner.show();
    this.masterService
      .postService('', 'StateDropDownList?CountryId=' + a.value)
      .subscribe((response) => {
        this.spinner.hide();
        this.StateList = response.body;
      });
  }
  savecity(input,apiName){
    this.spinner.show()
    this.masterService.globalBase(input, apiName).subscribe(res => {
      this.spinner.hide()
      this.successOrFailureMsg(res)
    });
  }
  async successOrFailureMsg(response) {
    console.log(response);

    if (response?.data?.Result?.Status == 200 || response?.body?.TransactionMessage?.Status == 200 || response?.body?.Result?.TransactionMessage?.Status == 200 || response?.data?.Result?.TransactionMessage?.Status == 200 || response?.body?.Status == 200 || response?.body?.Result?.Status == 200) {
      this.dialogRef.close(response);

      const dialogRef = this.dialog.open(CommonModalComponent, {
        width: "300px",
        disableClose: true,
        data: { action: "success", statusText: response?.body?.TransactionMessage?.Message ? response?.body?.TransactionMessage?.Message : response?.data?.Result?.Message ? response?.data?.Result?.Message : response?.data?.Result?.TransactionMessage?.Message ? response?.data?.Result?.TransactionMessage?.Message : response?.body?.Result?.TransactionMessage?.Message ? response?.body?.Result?.TransactionMessage?.Message : response?.body?.Message }
      });
    } else {
      const dialogRef = this.dialog.open(CommonModalComponent, {
        width: "300px",
        disableClose: true,
        data: { action: "delete", statusText: response?.body?.TransactionMessage?.Message ? response?.body?.TransactionMessage?.Message : response?.data?.Result?.Message ? response?.data?.Result?.Message : response?.data?.Result?.TransactionMessage?.Message ? response?.data?.Result?.TransactionMessage?.Message : response?.body?.Result?.TransactionMessage?.Message ? response?.body?.Result?.TransactionMessage?.Message : response?.body?.Message }
      });
    }

  }
  async getCountryfn() {
    this.response = await this.masterService.postService('', 'CountryDropDownList')

    this.CountryList = this.response.data
  }
}
